<script setup lang="ts">

useHead({
  htmlAttrs: {
    class: 'scroll-smooth'
  }
})

</script>

<template>
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UModals />
</template>